import React from 'react';
import { Link, useMatch } from 'react-router-dom';

function CustomNavLink({ to, children}) {
    const match = useMatch(to);

    return (
        <Link to={to} style={{ textDecoration: 'none', marginLeft: "8px", marginRight:"8px"}}>
            <span style={{ fontWeight: match ? 'bold' : 'normal', borderBottom: match ? "2px solid black" : "none", color:"black", textDecoration:"none" }}>
                {children}
            </span>
        </Link>
    );
}

export default CustomNavLink;
