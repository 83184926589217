import React from 'react';
import { Grid, Typography, Link } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Footer = () => {
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ height: '100px' }}></div> {/* Invisible element for margin */}
            <div
                style={{
                    backgroundColor: 'black',
                    color: 'white',
                    padding: '20px 0',
                    width: '100%',
                    position: 'static',
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
            >
                <Grid container justifyContent="center">
                    <Grid item>
                        <Typography variant="h6">Our Info</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item display="flex">
                        <EmailIcon/>{' '}
                        <Link href="mailto:ieximco.official@gmail.com" color="inherit">
                            ieximco.official@gmail.com
                        </Link>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item display="flex">
                        <WhatsAppIcon />{' '}
                        <Link href="https://wa.me/+8801521236825" color="inherit" target="_blank" rel="noopener noreferrer">
                            +8801521236825
                        </Link>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item display="flex">
                        <LocationOnIcon/>{' '} {/* Add LocationOnIcon */}
                        <Typography>15/3A, Road # 03, Sector # 13, Uttara, Dhaka-1230</Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Footer;
